import 'typeface-nunito'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

import useWindowDimensions from '../../hooks/useWindowDimensions'
import withStore from '../../state/withStore'

import zLevels from '../../utils/ui/zLevels'

import Charts from './Subpanels/Charts'
import Info from './Subpanels/Info'

import './DetailsPanel.css'


const DETAILS_WIDTH = 300
const DETAILS_HEIGHT= 350

const DETAILS_HORIZONTAL_SPACE = 40
const DETAILS_VERTICAL_SPACE = 40

const DETAILS_TOP_OFFSET = 100


export default withStore(({ rootStore }) => {
  const { width } = useWindowDimensions()
  const detailsColCount = parseInt(width / (DETAILS_WIDTH + DETAILS_HORIZONTAL_SPACE))

  return (
    rootStore.details && rootStore.details.map(({ item, data }, index) => (
      <div key={index} className="detailsPanel" style={{
        top: DETAILS_TOP_OFFSET + ((DETAILS_HEIGHT + DETAILS_VERTICAL_SPACE) * parseInt(index / detailsColCount)),
        width: DETAILS_WIDTH, height: DETAILS_HEIGHT,
        left: (DETAILS_WIDTH + DETAILS_HORIZONTAL_SPACE) * (index % detailsColCount) + 10,
        zIndex: zLevels.DETAILS
      }}>
          <div>
            <div class="header">{item.name}, {item.country}</div>
            <IconButton
              variant="contained"
              color="default"
              className="floatRight"
              onClick={() => rootStore.removeDetails(index)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Info item={item} month={rootStore.month} />
          <Charts data={data} />
      </div>
    )))
})
