const apiEndpoint = 'https://weathernomad.com/api'

const mapApi = `${apiEndpoint}/weather.php`
const yearlyApi = `${apiEndpoint}/yearly.php`
const searchApi = `${apiEndpoint}/search.php`


const fetchItem = (rootStore, item, multipleDetails) => {
  const detailsCount = rootStore.details.length

  rootStore.setLoader(true)
  if (!multipleDetails && detailsCount) {
    rootStore.removeDetails(detailsCount - 1)
  }
  
  fetch(`${yearlyApi}?lId=${item.id}`)
    .then(response => response.json())
    .then((data) => {
      const newItem = { item, data }
      rootStore.setLoader(false)

      multipleDetails ? rootStore.addDetails({ item, data }) : rootStore.setDetails(newItem)
    })
}

const fetchMap = (rootStore, onDone) => {
  const { bounds: { _northEast: ne, _southWest: sw }, zoom } = rootStore.location
  const month = rootStore.month
  
  rootStore.setLoader(true)
  fetch(`${mapApi}?lUp=${ne.lat}&lRight=${ne.lng}&lBtm=${sw.lat}&lLeft=${sw.lng}&displayLevel=${zoom + 1}&month=${month}`) // TODO: data resolution? as a switch?
    .then(response => response.json())
    .then(({ records }) => {
      rootStore.setLoader(false)
      rootStore.setRecords(records)
      if (onDone) {
        onDone()
      }
    })
}

const fetchSearch = (rootStore) => {
  const { searchPhrase } = rootStore

  if (searchPhrase.length < 3) {
    rootStore.setSearchResults([])
  } else {
    fetch(`${searchApi}?phrase=${searchPhrase}`)
      .then(response => response.json())
      .then(({ records }) => {
        rootStore.setSearchResults(records)
      })
  }
}


export {
  fetchItem,
  fetchMap,
  fetchSearch,
}