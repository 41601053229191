import React from 'react'

import 'typeface-nunito'

import colors from '../../../utils/ui/markerPalette'
import { getDayLength } from '../../../utils/daylength'

import './Info.css'


const formatCoordinate = (coordinate, positive, negative) => {
  const rounded = Math.round(coordinate * 100) / 100

  return (rounded < 0) ? `${Math.abs(rounded)}° ${negative}` : `${rounded}° ${positive}`
}


export default ({ item, month }) => (
  <>
    <div className="primary">
      Day length: {getDayLength(item.lat, item.lng, month)}
    </div>
    <div>
    {item.population && (
      <div className="secondary">
        Population: {item.population.toLocaleString()}
      </div>
    )}
    <div className="secondary">
      Coordinates: {`${formatCoordinate(item.lat, 'N', 'S')}, ${formatCoordinate(item.lng, 'E', 'W')}`}
    </div>
      <span style={{ float: 'right' }}>
        <span className='graphLegendItem' style={{ color: colors.COLOR_HIGH }}>
          High
        </span>
        <span className='graphLegendItem' style={{ color: colors.COLOR_AVG }}>
          Avg
        </span>
        <span className='graphLegendItem' style={{ color: colors.COLOR_LOW }}>
          Low
        </span>
        <span className='graphLegendItem' style={{ color: colors.COLOR_RAIN }}>
          Rain
        </span>
      </span>
    </div>
  </>
)
