import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

import withStore from '../../state/withStore'

import temperaturePalette from '../../utils/ui/temperaturePalette'
import zLevels from '../../utils/ui/zLevels'
import { convertUnits, getUnits } from '../../utils/units'

import './Palette.css'


export default withStore(({ rootStore }) => {
  const units = getUnits('temperature', rootStore.units)
  const unitsString = `° ${units}`

  return (
    <div className="palettePosition" style={{ zIndex: zLevels.LEGEND }}>
      <div className="paletteHeader">{unitsString}</div>
      {Object.keys(temperaturePalette).map((key, index) => {
        const temperature = parseInt(convertUnits('temperature', key.replace('temperature', '').replace('_', '-'), rootStore.units))

        return (
          <Tooltip title={`This color represents temperature in ${temperature} ${unitsString} range`}>
            <div key={index} className="paletteItem" style={{backgroundColor: temperaturePalette[key]}}>
              {temperature}
            </div>
          </Tooltip>
        )
      })}
    </div>
  );
})
