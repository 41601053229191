import React from 'react'
import { CircleMarker, Marker, Tooltip } from 'react-leaflet'

import withStore from '../../../state/withStore'

import temperaturePalette from '../../../utils/ui/temperaturePalette'
import { fetchItem } from '../../../utils/api'
import { trimName } from '../../../utils/string'

import MapItemRow from './MapItemRow/MapItemRow'

import './MapItem.css'


const getClassName = (temperature) => `temperature${temperature - (temperature % 10)}`

const MARKER_SCALE = 7

const AVG_MARKER_SIZE = MARKER_SCALE * 2
const HIGH_MARKER_SIZE = MARKER_SCALE * 1
const LOW_MARKER_SIZE = MARKER_SCALE * 3

// TODO: decide whether use the icon or not
// const myIcon = (temperature) => new L.Icon({
//   className: getClassName(temperature),
//   iconUrl: require('../../../details.svg'),
//   iconSize: [20, 20],
//   iconAnchor: [16, -4],
//   popupAnchor: null,
//   shadowUrl: null,
//   shadowSize: null,
//   shadowAnchor: [0, 0],
//   opacity: 1,
// });


export default withStore(({ rootStore, item }) => {
  const onMarkerClicked = () => {
    fetchItem(rootStore, item, false)
  }

  const onMarkerRightClicked = () => {
    fetchItem(rootStore, item, true)
  }

  const tooltip = (
    <Tooltip opacity={1} permanent={false}>
      <div>
        <div class="nunito">
          <b>{trimName(item.name)}</b>
        </div>
        <MapItemRow item={item} attribute="Average_High_Temperature" />
        <MapItemRow item={item} attribute="Average_Temperature" />
        <MapItemRow item={item} attribute="Average_Low_Temperature" />
        <MapItemRow item={item} attribute="Average_Precipitation" />
        <div className='detailsInfo'>
          {!rootStore.hasDetails(item.id) ? (
            rootStore.details.length > 0 ? 'Compare: right click' : 'Details: left click'
          ) : (
            'Details are shown'
          )}
        </div>
      </div>
    </Tooltip>
  )

  const isSelected = rootStore.hasDetails(item.id)

  const onClick = {
    onClick: !isSelected && onMarkerClicked,
    onContextMenu: !isSelected && onMarkerRightClicked,
  }

  return (
    <>
      {/* <Marker
        icon={myIcon(item.attributes.Average_High_Temperature)}
        key={item.lat}
        position={[item.lat, item.lng]}
        zIndexOffset={zLevels.MARKER}
        onClick={onMarkerClicked}
      >
      </Marker> */}

      {item.attributes.Average_Precipitation && (
        <CircleMarker
          center={[item.lat, item.lng]}
          radius={item.attributes.Average_Precipitation * 3}
          color="#00f"
          fillOpacity={0.1}
          stroke={false}
        />
      )}
      {item.attributes.Average_Temperature && (
        <CircleMarker
          center={[item.lat, item.lng]}
          color={temperaturePalette[getClassName(item.attributes.Average_Temperature)]}
          radius={AVG_MARKER_SIZE}
          fillOpacity={0.3}
          stroke={false}
          {...onClick}
        >{tooltip}</CircleMarker>
      )}
      {item.attributes.Average_High_Temperature && (
        <CircleMarker
          center={[item.lat, item.lng]}
          color={temperaturePalette[getClassName(item.attributes.Average_High_Temperature)]}
          radius={HIGH_MARKER_SIZE}
          fillOpacity={0.5}
          stroke={false}
          {...onClick}
        >{tooltip}</CircleMarker>
      )}
      {item.Average_Low_Temperature && (
        <CircleMarker
          center={[item.lat, item.lng]}
          color={temperaturePalette[getClassName(item.attributes.Average_Low_Temperature)]}
          radius={LOW_MARKER_SIZE}
          fillOpacity={0.145}
          stroke={false}
          {...onClick}
        >{tooltip}</CircleMarker>
      )}
    </>
  )
})
