import 'typeface-nunito'
import React from 'react'
import {
  Area,
  AreaChart,
  Bar,
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import withStore from '../../../state/withStore'

import colors from '../../../utils/ui/markerPalette'
import { convertUnits } from '../../../utils/units'


const MOTHS_COUNT = 12

const CHART_WIDTH = 310
const CHART_HEIGHT= 150


const normalizeForCharts = ({ high, avg, low, prec }, units) => {
  const result = []

  for (let i = 1; i <= MOTHS_COUNT; i++) {
    result.push({
      name: i,
      ...(high && { high: convertUnits('temperature', high[i], units) }),
      ...(low && { low: convertUnits('temperature', low[i], units) }),
      ...(avg && { avg: convertUnits('temperature', avg[i], units) }),
      ...(prec && { prec: convertUnits('precipitation', prec[i], units) }),
    })
  }

  return result
}


export default withStore(({ rootStore, data }) => (
  <>
    <LineChart
      width={CHART_WIDTH}
      height={CHART_HEIGHT}
      data={normalizeForCharts(data, rootStore.units)}
      margin={{ top: 15, left: -30, right: 15, bottom: 15 }}
    >
      <XAxis dataKey="name" />
      <YAxis />
      <CartesianGrid stroke="#ccc" strokeDasharray="1 4" />
      <Line type="monotone" dataKey="high" stroke={colors.COLOR_HIGH} dot={{ r: 1 }} />
      <Line type="monotone" dataKey="low" stroke={colors.COLOR_LOW} dot={{ r: 1 }} />
      <Line type="monotone" dataKey="avg" stroke={colors.COLOR_AVG} dot={{ r: 1 }} />
      {/* <Line type="monotone" dataKey="prec" stroke={colors.COLOR_RAIN} dot={{ r: 0 }} /> */}
      <Bar dataKey="low" stackId="x" stroke="#aaa">
        <LabelList dataKey="low" style={{ fill: "#aaa" }} />
      </Bar>
    </LineChart>
    <AreaChart
      width={CHART_WIDTH}
      height={CHART_HEIGHT}
      data={normalizeForCharts(data, rootStore.units)}
      margin={{ top: 15, left: -30, right: 15, bottom: 15 }}
    >
      <CartesianGrid strokeDasharray="1 4" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey="prec" stroke="#8884d8" fill="#8884d8" />
    </AreaChart>
  </>
))
