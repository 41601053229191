const COLOR_HIGH = '#f00'
const COLOR_AVG = 'orange'
const COLOR_LOW = '#00f'
const COLOR_RAIN = '#06e'


export default {
  COLOR_AVG,
  COLOR_HIGH,
  COLOR_LOW,
  COLOR_RAIN,
}