const isMetric = (units) => (units === 'm')

const isTemperature = (attribute) => attribute.toLowerCase().includes('temperature')


export const convertUnits = (attribute, value, units) => {
  if (isTemperature(attribute)) {
    return isMetric(units) ? (value - 32) / 1.8 : value
  } else {
    return isMetric(units) ? (value * 2.4) : value
  }
}

export const getUnits = (attribute, units) => {
  if (isTemperature(attribute)) {
    return isMetric(units) ? 'C' : 'F'
  } else {
    return isMetric(units) ? 'cm' : 'inch'
  }
}