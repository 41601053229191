export default {
  MAP: 1,
  DETAILS: 1800,  
  LEGEND: 1000,
  LOADER: 10000,
  MARKER: 1,
  OPTIONS: 1000,
  LOGO: 1000,
  SEARCH: 2000,
}
