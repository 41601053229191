import { decorate, observable, action } from 'mobx'

const DETAILS_PANEL_PIXEL_SPACE = 100000

const getMaxDetailsPanelsCount = () => parseInt(window.innerWidth * window.innerHeight / DETAILS_PANEL_PIXEL_SPACE)

export class BackendData {
  _records = []
  // TODO: set initial bounds (default or stored)
  _loader = false
  _location = { zoom: 3, bounds: { _northEast: { lat: 90, lng: 150 }, _southWest: { lat: -35, lng: -120 } }}
  _month = localStorage.getItem('month') || 1
  _units = localStorage.getItem('units') || 'm'
  _theme = localStorage.getItem('theme') || 'bright'
  _details = []
  _searchResults = []
  _searchLocation = null
  _searchPhrase = ''

  get records() {
    return this._records
  }

  setRecords(records) {
    this._records = records
  }

  get searchResults() {
    return this._searchResults
  }

  setSearchResults(searchResults) {
    this._searchResults = searchResults
  }

  get searchPhrase() {
    return this._searchPhrase
  }

  setSearchPhrase(searchPhrase) {
    this._searchPhrase = searchPhrase
  }

  clearSearch() {
    this.setSearchPhrase('')
    this.setSearchResults([])
  }

  get searchLocation() {
    return this._searchLocation
  }

  setSearchLocation(searchLocation) {
    this._searchLocation = searchLocation
  }

  get loader() {
    return this._loader
  }

  setLoader(loader) {
    this._loader = loader
  }

  get location() {
    return this._location
  }

  setLocation(location) {
    this._location = location
  }

  get units() {
    return this._units
  }

  setUnits(units) {
    localStorage.setItem('units', units)
    this._units = units
  }

  get theme() {
    return this._theme
  }

  setTheme(theme) {
    localStorage.setItem('theme', theme)
    this._theme = theme
  }

  get month() {
    return this._month
  }

  setMonth(month) {
    localStorage.setItem('month', month)
    this._month = month
  }

  get details() {
    return this._details
  }

  hasDetails(lookupId) {
    return this._details.some(({ item: { id } }) => lookupId === id )
  }

  setDetails(details) {
    this._details = [details]
  }

  addDetails(details) {
    // TODO: for now it's good enough, sometimes glitchy (details would go off screen on the bottom)
    if (this._details.length >= getMaxDetailsPanelsCount()) {
      this._details[0] = details
    } else {
      if (!this.hasDetails(details.item.id)) {
        this._details.push(details)
      }
    }
  }

  removeDetails(id) {
    this._details.splice(id, 1)
  }
}
decorate(BackendData, {
  _details: observable,
  _loader: observable,
  _location: observable,
  _month: observable,
  _records: observable,
  _searchLocation: observable,
  _searchPhrase: observable,
  _searchResults: observable,
  _theme: observable,
  _units: observable,
  addDetails: action,
  clearSearch: action,
  removeDetails: action,
  hasDetails: action,
  setDetails: action,
  setLoader: action,
  setLocation: action,
  setMonth: action,
  setRecords: action,
  setSearchLocation: action,
  setSearchPhrase: action,
  setSearchResults: action,
  setTheme: action,
  setUnits: action,
})

