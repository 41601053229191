import React from 'react'

import withStore from '../../../../state/withStore'

import colors from '../../../../utils/ui/markerPalette'
import { convertUnits, getUnits } from '../../../../utils/units'


export default withStore(({ rootStore: { units }, item, attribute }) => {
  const value = item.attributes[attribute]

  if (value) {
    const { color, label } = {
      'Average_Temperature': { label: 'Avg', color: colors.COLOR_AVG },
      'Average_High_Temperature': { label: 'High', color: colors.COLOR_HIGH },
      'Average_Low_Temperature': { label: 'Low', color: colors.COLOR_LOW },
      'Average_Precipitation': { label: 'Rain', color: colors.COLOR_RAIN },
    }[attribute]

    return (
      <div class="nunito">
        {`${label}: `}
        {/* TODO: style */}
        <span style={{ color, fontWeight: 'bold' }}>
          {`${parseInt(convertUnits(attribute, value, units))} ${getUnits(attribute, units)}`}
        </span>
      </div>
    )
  } else {
    return null
  }
})
