import React from 'react'

import zLevels from '../../utils/ui/zLevels'

import './Main.css'


export default () => (
  <div className="logo" style={{ zIndex: zLevels.LOGO }}>
    Weather Nomad 
  </div>
)
