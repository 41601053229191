import React from 'react'

import DetailsPanel from '../Panels/DetailsPanel/DetailsPanel'
import LegendPanel from '../Panels/LegendPanel/Palette'
import MapPanel from '../Panels/MapPanel/MapPanel'
import OptionsPanel from '../Panels/OptionsPanel/Main'
import PrimaryPanel from '../Panels/PrimaryPanel/Main'
import SearchResults from '../Panels/SearchResults/SearchResults'


export default () => (
  <div>
    <PrimaryPanel />
    <OptionsPanel />
    <LegendPanel />
    <MapPanel />
    <DetailsPanel />
    <SearchResults />
  </div>
)
