import React from 'react'

import withStore from '../../state/withStore'

import zLevels from '../../utils/ui/zLevels';
import { fetchItem } from '../../utils/api'

import './SearchResults.css'


const MAX_SEARCH_RESULTS = 20


export default withStore(({ rootStore }) => {
  const navigateToLocation = (item) => {
    rootStore.clearSearch()
    rootStore.setSearchLocation(item)
    fetchItem(rootStore, item)
  }

  return (rootStore.searchResults && rootStore.searchResults.length) ? (
    <div className="searchResultsList" style={{ zIndex: zLevels.SEARCH }}>
      {rootStore.searchResults.filter((_item, index) => index < 20).map((item, index) => (
        <div key={index} className="singleResult" onClick={() => navigateToLocation(item)}>
          {item.name}, {item.country}
        </div>
      ))}
      {(rootStore.searchResults.length >= MAX_SEARCH_RESULTS) && (
        <div>
          ...
        </div>
      )}
    </div>
  ) : null
})
