import React from 'react'

import 'typeface-nunito'

import { Provider } from 'mobx-react'

import { BackendData } from './state/store'
import { fetchMap } from './utils/api'

import Main from './Main/Main'
import './index.css'

export default () => {
  const store = new BackendData()

  fetchMap(store)

  return (
    <Provider rootStore={store} >
      <Main />
    </Provider>
  )
}
