const componentToHex = (c) => (c.toString(16).length === 1 ? '0' : '') + c.toString(16)
const rgb = (r, g, b) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`


export default {
  temperature110: rgb(255, 0, 0),
  temperature100: rgb(255, 123, 0),
  temperature90: rgb(255, 208, 0),
  temperature80: rgb(209, 224, 0),
  temperature70: rgb(104, 231, 0),
  temperature60: rgb(0, 202, 158),
  temperature50: rgb(0, 94, 122),
  temperature40: rgb(255, 255, 255),
  temperature30: rgb(255, 255, 255),
  temperature20: rgb(173, 173, 173),
  temperature10: rgb(131, 131, 131),
  temperature0: rgb(77, 77, 77),
  temperature_10: rgb(78, 78, 78),
  temperature_20: rgb(80, 80, 80),
  temperature_30: rgb(71, 71, 71),
}