import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import React from 'react'
import Select from '@material-ui/core/Select'
import Tooltip from '@material-ui/core/Tooltip'

import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import withStore from '../../state/withStore'

import zLevels from '../../utils/ui/zLevels'
import { fetchSearch, fetchMap } from '../../utils/api'

import './Main.css'


const handleChangeMonth = (value, rootStore, onDone) => {
  rootStore.setMonth(value)
  fetchMap(rootStore, onDone)
}

const handlePlayMonths = (rootStore, month = 1) => {
  if (month > 12) {
    return
  }
  setTimeout(() => handleChangeMonth(month, rootStore, () => handlePlayMonths(rootStore, month + 1)), 50)
  
}

const handleChangeUnits = (value, rootStore) => {
  rootStore.setUnits(value)
  fetchMap(rootStore)
}

const handleChangeTheme = (value, rootStore) => {
  rootStore.setTheme(value)
  // fetchMap(rootStore)
}

const handleChangeInput = (value, rootStore) => {
  rootStore.setSearchPhrase(value)
  fetchSearch(rootStore)
}


export default withStore(({ rootStore }) => {
  const themeStyle = rootStore.theme === 'bright' ? {} : { color: 'white' }

  return (
    <>
      {rootStore.loader && (
        <div className="loader" style={{ zIndex: zLevels.LOADER }}>
          <div className="lds-ring" style={{ zIndex: 10001 }}><div></div><div></div><div></div><div></div></div>
        </div>
      )}
      <div className="flexRow options" style={{ zIndex: zLevels.OPTIONS }}>
        <div className="flexRow">
          <div className="flexItem">
            <Input
            style={themeStyle}
              placeholder="Location search"
              value={rootStore.searchPhrase}
              onChange={({ target: { value }}) => handleChangeInput(value, rootStore)}
            />
            <Tooltip title="Clear search field">
              <IconButton
                variant="contained"
                color="default"
                className="floatRight"
                onClick={() => rootStore.clearSearch()}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="flexRow">
          <div>
            <Select
              style={themeStyle}
              value={rootStore.month}
              onChange={({ target: { value }}) => handleChangeMonth(value, rootStore)}
              zIndex
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </Select>
          </div>
        </div>
        <div className="flexRow">
          <div className="flexItem">
            <Select style={themeStyle} value={rootStore.units} onChange={({ target: { value }}) => handleChangeUnits(value, rootStore)}>
              <option value="m">Metric</option>
              <option value="i">Imperial</option>
            </Select>
          </div>
          <div className="flexItem">
            <Select style={themeStyle} value={rootStore.theme} onChange={({ target: { value }}) => handleChangeTheme(value, rootStore)}>
              <option value="bright">Bright</option>
              <option value="dark">Dark</option>
              <option value="watercolor">Watercolor</option>
            </Select>
          </div>
          <div className="flexItem">
            <Tooltip title="Animates current map month by month">
              <Button
                variant="contained"
                color="default"
                size="small"
                onClick={() => {
                  handlePlayMonths(rootStore)
                }}
              >
                Play
              </Button>
            </Tooltip>
          </div>
      </div>
    </div>
    <div class="footer" style={{ zIndex: zLevels.OPTIONS }}>
      Switch to <a href="https://weatherhopper.com/index.htm">old version</a>, for support contact: <a href="mailto:support@weathernomad.com">support@weathernomad.com</a>
    </div>
    </>
  );
})
